.btn {
    
}

.btn-primary {
    @include btnPrimary($primary_1, $neutral_5);

    &.orange {
        @include btnPrimary($primary_1, $neutral_5);
    }

    &.dark-blue {
        @include btnPrimary($secondary_1, $neutral_5);
    }

    &.light-blue {
        @include btnPrimary($primary_2, $neutral_5);
    }

    &.white {
        @include btnPrimary($neutral_5, $secondary_1);
    }

    &.blue {
        @include btnPrimary($primary_2, $neutral_5);
    }
}

.btn-hollow {
    &.orange {
        @include btnHollow($primary_1, $primary_1);
    }

    &.dark-blue {
        @include btnHollow($secondary_1, $secondary_1);
    }

    &.light-blue {
        @include btnHollow($primary_2, $primary_2);
    }

    &.white {
        @include btnHollow($neutral_5, $neutral_5);
    }

    &.blue {
        @include btnHollow($primary_2, $primary_2);
    }
}

.btn-secondary {
    @include btnSecondary($neutral_5);

    &.orange {
        @include btnSecondary($primary_1);
    }
}

.btn-tertiary {
     @include btnTertiary();
}

.btn-offcanvas {
    @include btnReset;
    width: 32px;
    height: 32px;
    padding: 6px 0;
    outline: 0;

    svg {
        position: absolute;
        display: block;


        &.menu-default {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            color: white;
        }

        &.menu-close {
            display: none;
            width: 18px;
            height: 18px;
            right: 0;
            top: 7px;
            color: $neutral_2;
        }
    }

    &:focus { outline: 0; }

    &.active {
        svg.menu-default { display: none;}
        svg.menu-close { display: block;}
    }

    .sr-only {
        display: none;
        visibility: hidden;
    }
}

/*
Margin corrections when used in module
 */
//.modu
