.module-content {
    p:last-child {
        margin-bottom: 0;
    }

    //Default column structure
    .content-wrapper {
        @include make-col-ready();
        @include make-col(10);
        @include make-col-offset(1);
    }

    //992px +
    @include media-breakpoint-up(lg) {
        .content-wrapper {
            @include make-col(8);
            @include make-col-offset(2);
        }
    }

    //When used in donation page, different column structure
    .donation-page .content-column & {

        .content-wrapper {
            @include make-col(10);
            @include make-col-offset(1);
        }

        //992px +
        @include media-breakpoint-up(lg) {
            .content-wrapper {
                @include make-col(12);
                @include make-col-offset(0);
            }
        }
    }
}


