.module-image-gallery {
    /*
    Faux border / underline
     */
    .swiper-container:after,
    .img-wrapper:after {
        content: '';
        display: block;
        width: 90%;
        height: 1px;
        background-color: $neutral_2;
        opacity: 0.3;
        margin: auto;
    }

    .swiper-slide,
    .img-wrapper {
        background: white;
    }

    .img-caption {
        width: 100%;
        padding: 25px 0 19px 0;
        text-align: center;
    }

    p {
        @include fontSize(12px);
        @include lineHeight(20px);
        color: $neutral_2;
        margin: 0;
    }

    //992px +
    @include media-breakpoint-up(lg) {

        /*
        Faux border / underline
         */
        .swiper-container:after {
            width: 70%;
        }

        p {
            @include fontSize(16px);
            @include lineHeight(24px);
            color: $neutral_2;
            margin: 0;
        }
    }
}
