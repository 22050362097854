.module {
    margin: 30px 0;

    &:first-child {
        margin-top: 0;
    }

    // Support the bleed in the footer, the background image module overrides this to support image behind the subscribe button.
    &:last-child {
        margin-bottom: 120px;
    }

    @include media-breakpoint-up(lg) {
        margin: 80px 0;

        // support the bleed in the footer, the background image module overrides this to support image behind the subscribe button.
        &:last-child {
            margin-bottom: 170px;
        }
    }
}
