$browser-context: $fontSize; // Default

@function calculateRem($pixels, $context: $browser-context) {
    @return #{($pixels/$context)}rem;
}

@function calculateEm($pixels, $context: $browser-context) {
    @return #{($pixels/$context)}em;
}

@function calculateVW($screensize, $pixels){
    @return #{($pixels/$screensize)*100}vw;
}

@mixin fontSize($size) {
    font-size: calculateRem($size);
}

@mixin lineHeight($size) {
    line-height: calculateRem($size);
}

@mixin fontSizeInVW($screensize, $pixels){
    font-size: calculateVW($screensize, $pixels);
}

@mixin lineHeightInVW($screensize, $pixels){
    line-height: calculateVW($screensize, $pixels);
}