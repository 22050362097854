//TODO  document
/********************************
Aspect Ratio
Source:
https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/

Usage:
Note: class names are for illustration only; any selector will do.
Aspect ratio is entirely flexible - eg, (1,4),  (6-8), whatever you like

.sixteen-nine {
    @include aspect-ratio(16, 9);
}

.four-three {
    @include aspect-ratio(4, 3);
}

.one-one {
    @include aspect-ratio(1, 1);
}

********************************/
@mixin aspectRatio($width, $height, $childSelector: '.content') {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > #{$childSelector} {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
