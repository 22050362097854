.icon-label {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
    margin: 0;
    color: $primary_1;
    @include lineHeight(12px);

    .icon {
        width: 36px;
        height: 36px;
        min-width: 36px;
        margin-right: 8px;
    }

    .txt {
        display: block;
        @include fontSize(10px);
        @include lineHeight(12px);
        position: relative;
        letter-spacing: 3px;
        text-transform: uppercase;
    }

    //992px +
    @include media-breakpoint-up(lg) {
        .icon {
            width: 48px;
            height: 48px;
            min-width: 48px;
            margin-right: 10px;
        }
    }
}
