.module-map {

    /*
    Core, default mobile first styles here
    */
    .map-wrapper {
        @include aspectRatio(138, 89, '.map-inner');
        background: $secondary_2;
    }

    .static-image,
    .map-embed {
        width: 100%;
        height: 100%;
    }

    .map-embed {
        display: none;
    }

    .text-link {
        text-align: right;
        padding: 18px 0 6px 0;
        border-bottom: solid 1px rgba($neutral_2, 0.4);

        a {
            text-decoration: none;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        .map-wrapper {
            @include aspectRatio(180, 83, '.map-inner');
            width: 100%;
            max-height: 664px;
        }

        .static-image {
            display: none;
        }

        .map-embed {
            display: block;
        }

        iframe {
            width: 100%;
            height: 100%;
            border: none;
        }

        .text-link {
            display: none;
        }
    }
}
