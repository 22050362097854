.module-video {
    width: 100%;
    position: relative;

    .video-player {
        width: 100%;
    }

    .tint {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.55;
    }

    .content-block {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .play-button {
        display: block;
        margin-bottom: 9px;

        .hover {
            display: none;
        }

        &:hover {
            .default {
                display: none;
            }

            .hover {
                display: block;
            }
        }
    }

    svg {
        width: 65px;
        height: 65px;
    }

    h1 {
        @include fontSize(20px);
        @include lineHeight(24px);
        margin-bottom: 0;
    }

    .plyr--paused.plyr__poster-enabled .plyr__poster {
        opacity: 1
    }

    .plyr__video-wrapper {
        width: 100%;
        }
        .plyr__video-wrapper iframe {
        width: 100%;
        }

    @include media-breakpoint-up(lg) {
        h1 {
            @include fontSize(25px);
            @include lineHeight(40px);
        }
    }
}
