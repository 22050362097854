#utility-navigation {

    /*
    Core, default mobile first styles here
    */
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li {
        position: relative;
        display: inline-block;
        margin-bottom: 8px;
        margin-right: 16px;
    }

    a {
        @include fontSize(14px);
        @include lineHeight(24px);
        color: $neutral_2;
        text-decoration: none;
    }


    //992px +
    @include media-breakpoint-up(lg) {
        @include animate-custom(#{'opacity'}, 750ms);
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        text-align: right;
        padding-top: 18px;

        ul {
            display: block;
            float: right;
            margin-bottom: 10px;
        }

        li {
            display: block;
            float: left;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            @include animate-custom(#{'color'}, 500ms);
            color: white;
            text-decoration: none;

            &:hover {
                color: $primary_1;
            }
        }

        #master-header.light & {
            opacity: 0;
            pointer-events: none;
        }
    }
}
