#primary-navigation {

    /*
    Core, default mobile first styles here
    */
    padding-top: 80px;

    ul {
        margin: 0;
        margin-bottom: 35px;
        padding: 0;
        list-style-type: none;
    }

    li {
        border-bottom: 2px solid #e4e4e4;
    }

    /*
    Top level nav items
     */
    li > a {
        @include fontSize(16px);
        @include lineHeight(24px);
        position: relative;
        display: block;
        width: 100%;
        padding-top: 24px;
        padding-bottom: 16px;
        font-family: $fontPrimary;
        font-weight: 900;
        color: $neutral_2;
        text-decoration: none;
    }

    .item-name { display: none; }

    .btn-accordion-trigger {
        @include fontSize(16px);
        @include lineHeight(24px);
        position: relative;
        display: block;
        width: 100%;
        padding: 24px 0 16px 0;
        background: none;
        border: none;
        font-family: $fontPrimary;
        font-weight: 900;
        color: $neutral_2;
        text-decoration: none;
        text-align: left;
        outline: none;

        .icon {
            @include animate-custom(#{'transform'}, 500ms);
            position: absolute;
            display: block;
            width: 20px;
            height: 11px;
            right: -1px;
            top: 31px;
        }
    }

    .btn-primary {
        display: block;
        width: 100%;
        margin-bottom: 17px;
    }

    /*
    Subnav lists
     */
    ul > li> ul {
        @include animate-custom(#{'max-height'}, 500ms);
        overflow: hidden;
        max-height: 0;
        height: auto;
        margin-bottom: 0;
        margin-top: 2px;

        li {
            border-bottom: 0;

            &:last-child {
                margin-bottom: 18px;
            }
        }

        a {
            @include fontSize(14px);
            @include lineHeight(32px);
            margin: 0;
            padding: 0;
            text-decoration: none;
            font-weight: normal;

            &:after {
                display: none;
            }
        }
    }

    /*
    Active accordion subnav items
     */
    li.active {
        > a {
            color: $primary_1;

            &:after {
                transform: rotate(-180deg);
                transform-origin: center center;
            }
        }

        > ul {
            max-height: 220px;
        }

        .btn-accordion-trigger {
            color: $primary_1;
            .icon {
                transform: rotate(-180deg);
            }
        }
    }

    /*
    Primary dontate / book etc buttons
     */
    .primary-btn-wrapper {
        margin-bottom: 32px;

        a {
            display: block;
            width: 100%;
            margin-bottom: 17px;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        width: 100%;
        position: relative;
        padding-top: 0;

        ul:first-child {
            display: inline-block;
        }

        li {
            position: relative;
            display: inline-block;
            padding: 0 15px 15px 15px;
            border-bottom: none;

            a {
                display: block;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        /*
        Top level nav items
         */
        li.has-children,
        li > a {
            @include fontSize(20px);
            @include lineHeight(32px);
            @include animate-custom(#{'color'}, 500ms);
            padding: 0;
            border-bottom: none;
            color: white;

            &:hover {
                color: $primary_1;
            }

            &:after {
                display: none;
            }
        }

        li.has-children {
            display: inline-block;
            padding: 0 15px 15px 15px;
            width: auto;
        }

        .item-name {
            display: block;
            font-weight: 900;
        }
        .btn-accordion-trigger { display: none; }

        /*
        Subnav lists
         */
        ul > li> ul {
            @include animate-custom(#{'opacity, transform'}, 500ms);
            position: absolute;
            width: 300px;
            height: auto;
            top: 70px;
            left: 50%;
            opacity: 0;
            max-height: none;
            transform: translateX(-50%) translateY(0);
            padding: 7px 36px 0 36px;
            overflow: visible;
            background: white;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
            border-radius: 3px;
            text-align: left;
            pointer-events: none;

            /*
            Arrow effect
             */
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                top: -12px;
                transform: translateX(-50%);
                border-style: solid;
                border-width: 0 12px 12px 12px;
                border-color: transparent transparent #ffffff transparent;
            }

            li {
                display: block;
                margin-right: 0;
                padding: 0;
                border-bottom: solid 1px rgba(0, 0, 0, 0.15);

                &:last-child {
                    border-bottom: 0;
                }
            }

            a {
                @include fontSize(16px);
                @include lineHeight(24px);
                color: black;
                text-decoration: none;
                padding: 19px 0  13px 0;

                &:hover {
                    color: $primary_1;
                }
            }
        }


        /*
        Hover > show dropdown subnav
         */
        ul {
            > li {
                &:hover {
                    > ul {
                        opacity: 1;
                        transform: translateX(-50%) translateY(-25px);
                        pointer-events: all;
                    }
                }
            }
        }

        /*
        On scroll version
         */
        #master-header.light & {
            transform: translateY(10px);

            li,
            li > a {
                 color: $neutral_2;
            }

            li > a:hover {
                color: $primary_1;
            }

            .btn-primary.blue {
                @include btnPrimary($primary_2, $neutral_5);
            }
        }


        /*
        Primary buttons
         */
        .primary-btn-wrapper {
            display: inline-block;

            .btn-primary.blue {
                @include btnPrimary($neutral_5, $secondary_1);
            }

            a {
                display: inline-block;
                width: auto;
                margin-bottom: 0;

                &:first-child {
                    margin-right: 19px !important; //Sorry
                }
            }
        }
    }

    //1200px +
    @include media-breakpoint-up(xl) {

        ul:first-child {
            margin-right: 70px;
        }

        li {
            margin-right: 30px;
        }
    }
}
