.module-background-image {
    margin: 0;
    padding: 40px 0;

    .module {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        padding: 80px 0;
    }
}

.module:last-child.module-background-image {
    padding-bottom: 120px;
    margin-bottom: 0;

    //992px +
    @include media-breakpoint-up(lg) {
        padding-bottom: 170px;
        margin-bottom: 0;
    }
}