.module-tickets-popup {
    text-align: center;

    iframe {
        border: 0;
    }

    .modal{
       padding: 0 !important; // To override the inline styling which addes extra 17px right padding
    }

    .close {
        position: absolute;
        top: -6px;
        right: 3px;
        right: 0;
        border: 0;
        font-size: 22px;
        font-weight: bold;
        color: white;
        cursor: pointer;
        float: right;
        background: transparent;
        color: black;
    }

    // 992px +
    @include media-breakpoint-up(lg) {
        
        .modal-dialog {
            max-width: 900px;
        }
    }
}