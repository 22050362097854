.module-image-grid {

    /*
    Core, default mobile first styles here
    */
    picture {
        display: block;
        width: 90%;
    }

    .col-4:first-child {
        picture {
            float: left;
        }
    }

    .col-4 {
        picture {
            margin: auto;
        }
    }

    .col-4:last-child {
        picture {
            float: right;
        }
    }
}
