.module-intro-text {
    text-align: center;

    .heading-block {
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 20px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 46px;
            height: 2px;
            background-color: $primary_1;
        }

    }

    .title {
        position: relative;
        margin: 0;
        padding: 0;
    }

    .subtitle {
        @include fontSize(24px);
        @include lineHeight(32px);
        margin: 12px 0;
        padding: 0;
        font-family: $fontSecondary;
        font-weight: bold;
        color: $neutral_2;
    }

    p {
        color: #49403A;
        &:last-child {
            margin-bottom: 0;
        }
    }

    /*
    When module is first item in page
     */
    &:first-child {
        padding-top: 120px;
    }

    //992px +
    @include media-breakpoint-up(lg) {

        .heading-block {
            padding-bottom: 34px;
            margin-bottom: 44px;
        }

        .subtitle {
            margin: 0;
            padding: 0;
            @include fontSize(32px);
            @include lineHeight(64px);
        }

        &:first-child {
            padding-top: 185px;
        }
    }
}

/*
Only applied when module is used directly after info CTAs,
 */
.module-info-ctas + .module-intro-text {
    //992px +
    @include media-breakpoint-up(lg){
        margin-top: 138px;
    }
}
