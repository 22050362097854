.module-hero-slider {
    /*
    TODO: discus this height; this was intended to be height
     of info CTAs, which now have flexible height
     */

    $module-hero-slider-height-mobile: calc(100vh - 120px);

    /*
    Core, default mobile first styles here
    */
    width: 100%;
    height: $module-hero-slider-height-mobile;

    .inner,
    .swiper-container,
    .swiper-wrapper,
    .swiper-slide,
    .row {
        height: 100%;
    }

    .inner,
    .swiper-slide {
        background: $secondary_1;
    }
    /*
    Hide first - fade in triggered on
     */
    .swiper-container {
        @include animate-custom(#{'opacity'}, 750ms, 500ms, $noEase);
        opacity: 0;

        &.ready {
            opacity: 1;
        }
    }

    .swiper-slide {
        overflow: hidden;
    }

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-align: center;
        background-size: cover;
        background-position: center center;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .content-col,
    .content-wrapper {
        height: 100%;
    }

    .content {
        padding-bottom: 105px;
    }

    .heading {
        @include fontSize(32px);
        @include lineHeight(40px);
        margin-bottom: 24px;
        font-weight: 900;
        color: #FFFFFF;

        &.brand-name {
            @include fontSize(22px);
            @include lineHeight(22px);
            margin-bottom: 0;
        }
    }

    video {
        display: none;
    }

    .swiper-pagination {
        bottom: 65px;
    }
    //992px +
    @include media-breakpoint-up(lg) {
        @include aspectRatio(16,9, '.inner');
        height: auto;
        min-height: 559px;
        max-height: 800px;


        .background {
            @include animate-custom(#{'transform'}, 5000ms, $easing: $noEase);
        }

        .plyr,
        .plyr__video-wrapper {
            height: 100%;
            display: block;
        }

        video {
            display: block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        .content {
            padding-bottom: 196px;
        }

        .heading {
            @include fontSize(36px);
            @include lineHeight(48px);
            margin-bottom: 32px;
        }

        .swiper-pagination {
            bottom: 142px;
            right: 64px;
            left: auto;
        }
        /*
        Pre animation states
         */
        .heading {
            @include animate-custom(#{'transform, opacity'}, 2500ms);
            transform: translateX(200px);
            opacity: 0;
        }

        a {
            @include animate-custom(#{'opacity'}, 2000ms, $delay: 750ms);
            opacity: 0;
        }
        /*
        Post animation states
         */
        .swiper-slide-active,
        .swiper-slide-prev {
            .background {
                transform: scale(1.1);
            }

            .heading {
                transform: translateX(0);
                opacity: 1;
            }

            a {
                opacity: 1;
            }
        }
    }
    //1200px +
    @include media-breakpoint-up(xl) {
        .heading {
            @include fontSize(48px);
            @include lineHeight(64px);
            margin-bottom: 32px;
        }
    }
}
