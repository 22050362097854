.module-full-width-cta {

    &.orange {
        .inner-wrap { background: $primary_1;}
    }

    &.blue {
        .inner-wrap { background: $primary_2;}
    }

    &.neutral {
        .inner-wrap { background: $neutral_3;}
    }


    /*
    Core, default mobile first styles here
    */
    .inner-wrap {
        width: 100%;
        padding: 35px 35px 40px 35px;
        text-align: center;
    }

    p {
        @include fontSize(20px);
        @include lineHeight(32px);
        color: $neutral_4;
        margin-bottom: 11px;
        position: relative;
        font-weight: 900;
        //text-align: left?;
    }

    .btn-primary {
        display: inline-block;
    }

    //992px +
    @include media-breakpoint-up(lg) {


        .inner-wrap {
            @include clearfix();
            padding: 56px 120px;
        }

        p {
            @include fontSize(40px);
            @include lineHeight(56px);
            display: block;
            float: left;
            margin-bottom: 0;

        }

        .btn-primary {
            display: block;
            float: right;
            margin-top: 4px;
        }

        //1200px +
        @include media-breakpoint-up(xl) {
            p {
                padding-left: 73px;

                &:before {
                    content: '';
                    display: block;
                    width: 48px;
                    height: 2px;
                    background-color: $neutral_4;
                    position: absolute;
                    left: 0;
                    top: 24px;
                }
            }
        }
    }
}
