.module-button {
    /*
    Core, default mobile first styles here
    */
    text-align: center;

    &.small-top-margin {
        margin-top: -15px;
    }

    //992px +
    @include media-breakpoint-up(lg) {
        &.small-top-margin {
            margin-top: -40px;
        }
    }
}
