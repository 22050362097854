.module-promo-tiles-block {
    $halfColumn: (percentage(1 / 12) / 2);

    /*
    Core, default mobile first styles here
    */
    .tile {
        @include make-col-ready();
        @include make-col(10);
        @include make-col-offset(1);
        padding: 0;
        margin-bottom: verticalGrid(4);
    }

    .tile-img {
        width: 100%;

        a {
            display: block;
            overflow: hidden;
        }

    }

    .tile-content {
        padding: 34px 10% 34px 10%;
        background: white;
        color: $neutral_1;

        p {
            @include fontSize(18px);
            @include lineHeight(28px);
            font-weight: 900;
            color: inherit;
            margin: 0;
        }

        a {
            @include animate-custom(#{'color'}, 750ms);
            color: inherit;
            text-decoration: none;

            &:hover {
                color: $primary_1;
            }
        }

        p.category {
            @include fontSize(16px);
            @include lineHeight(24px);
            position: relative;
            padding-left: 42px;
            margin-bottom: 13px;
            font-weight: normal;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 32px;
                height: 1px;
                left: 0;
                top: 11px;
                background-color: $neutral_1;
            }

        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        .tile {
            @include make-col-ready();
            @include make-col(5);
            @include make-col-offset(1);
            padding-left: 0;
            padding-right: $halfColumn;
            margin-bottom: 0;

            &:nth-child(even){
                @include make-col-offset(0);
                padding-left: $halfColumn;
                padding-right: 0;
                margin-top: verticalGrid(10);
            }

            &:hover {
                img {
                    transform: scale(1.05);
                    transform-origin: center center;
                }
            }
        }

        .tile-img {
            img {
                @include animate-custom(#{'transform'}, 1500ms);
            }
        }


        .tile-content {
            padding: 45px 21%; //Ideally should be 1/5 but tiles break from grid structure

            p {
                @include fontSize(24px);
                @include lineHeight(40px);
            }

            p.category {
                @include fontSize(20px);
                @include lineHeight(32px);
                padding-left: 0;
                margin-bottom: 11px;

                &:before {
                    width: 48px;
                    left: -64px;
                    top: 16px;
                }
            }
        }
    }
}
