.module-block-quote {

    /*
    Core, default mobile first styles here
    */
    padding: 40px 0;  //TODO:  confirm with design

    .col {
        position: relative;

        /*
        Faux border
         */
        &:before {
            content: '';
            display: block;
            height: calc(100% - 22px);
            width: 10px;
            position: absolute;
            left: 0;
            top: 11px;
            background-color: $primary_1;
        }
    }

    p {
        @include fontSize(24px);
        @include lineHeight(40px);
        padding-left: 32px;
        font-weight: bold;
        color: $neutral_1;
        font-family: $fontSecondary;
        margin: 0;
    }


    //992px +
    @include media-breakpoint-up(lg) {
        padding: 89px 0 92px 0;

        .col {
            /*
            Faux border
             */
            &:before {
                height: calc(100% - 36px);
                width: 16px;
                top: 16px;
            }
        }

        p {
            @include fontSize(40px);
            @include lineHeight(64px);
            padding-left: 24px;
        }
    }
}
