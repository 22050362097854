.module-feature-slider {
    position: relative;

    .image {
        position: relative;
        width: 100%;
        height: 500px;
        overflow: hidden;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: auto;
            height: 500px;
        }
    }

    .caption {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background: rgba(0,0,0,0.45);
        color: #FFFFFF;

        .caption-content {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            text-align: center;

            .title {
                font-size: 2.5em;
            }
        }
    }

    
}
