/*
    Do not use these mixins generally throughout the css, instead create a
    reference to them in the ui/_buttons.scss file.

    The reason behind this is that using mixins like this could lead to css bloat when all the
    selectors are added to the various buttons are in the site, in my experience its best to
    use class names in the markup instead.

    otherwise all the selectors will be included in eveery use of the mixin.

    So you would end up with alot more css in the compiled version.

    - Blanksy
*/

@mixin btnReset(){
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}

@mixin btnDisabled(){
    cursor: pointer;
    pointer-events: none;
}

@mixin btnPrimary($bgColour, $textColour, $darkenValue: 15){
    @include btnReset();
    @include fontSize(12px);
    @include lineHeight(14px);
    @include animate-custom(#{'background-color'}, 500ms);
    display: inline-block;
    padding: 13px 20px 10px 20px;
    background-color: $bgColour;
    border-radius: 4px;
    color: $textColour;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background-color: darken($bgColour, $darkenValue);
        text-decoration: none;
        color: $textColour;
    }

    //992px +
    @include media-breakpoint-up(lg) {
        @include fontSize(14px);
        @include lineHeight(14px);
        padding: 18px 32px 16px 32px;
    }

    .module-button & {
        margin-bottom: 30px;
    }

    &:last-child {
        .module-button & {
            margin-bottom: 0;
        }
    }
}

@mixin btnHollow($bgColour, $textColour, $darkenValue: 15){
    @include btnReset();
    @include fontSize(12px);
    @include lineHeight(14px);
    @include animate-custom(#{'background-color'}, 500ms);
    display: inline-block;

    padding: 11px 18px 8px 18px;
    border: 2px solid $bgColour;
    background-colour: white;
    border-radius: 4px;
    color: $textColour;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        border-color: darken($bgColour, $darkenValue);
        text-decoration: none;
        color: darken($textColour, $darkenValue);
    }

    //992px +
    @include media-breakpoint-up(lg) {
        @include fontSize(14px);
        @include lineHeight(14px);
        padding: 16px 30px 14px 30px;
    }

    .module-button & {
        margin-bottom: 30px;
    }

    &:last-child {
        .module-button & {
            margin-bottom: 0;
        }
    }
}

@mixin btnSecondary($colour){
    @include btnReset();
    @include fontSize(12px);
    @include lineHeight(16px);
    @include animate-custom(#{'color'}, 500ms);
    display: inline-block;
    position: relative;
    padding-left: 49px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: $colour;
    font-weight: 600;
    text-decoration: none;


    svg {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 36px;
        height: 36px;
        margin-right: 8px;
    }

    &:hover {
        color: darken($colour, 15);
        text-decoration: none;
    }


    @include media-breakpoint-up(lg) {
        @include fontSize(14px);
        @include lineHeight(14px);
        padding-left: 71px;

        svg {
            width: 56px;
            height: 56px;
            margin-right: 14px;
        }
    }

}

@mixin btnTertiary() {
    @include btnReset();
    @include fontSize(12px);
    @include lineHeight(16px);
    @include animate-custom(#{'color'}, 500ms);
    display: inline-block;
    position: relative;
    padding-left: 37px;
    text-transform: uppercase;
    color: $secondary_3;
    font-weight: 700;
    letter-spacing: 2px;
    text-decoration: none;

    .icon {
        @include animate-custom(#{'transform'}, 500ms);
        display: block;
        width: 23px;
        height: 11px;
        position: absolute;
        left: 0;
        top: 2px;
    }

    &:hover {
        color: lighten($secondary_3, 15);
        text-decoration: none;

        .icon {
            transform: translateX(5px);
        }
    }
}