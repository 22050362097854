.module-two-column-text-list {

    /*
    Core, default mobile first styles here
    */
    .text-item {
        padding-top: 30px;

        &:last-child {
            hr {
                display: none;
            }
        }
    }

    .content-wrap {
        padding-top: 6px;
    }

    .btn-secondary {
        margin-top: 15px;
    }

    h2 {
        color: $neutral_1;
    }

    h3 {
        color: $neutral_2;
    }

    hr {
        width: 100%;
        height: 1px;
        background: $neutral_2;
        opacity: 0.3;
        border: none;
        margin: 0;
        margin-top: 30px;
    }


    //576px +
    @include media-breakpoint-up(sm) {

    }

    //768px +
    @include media-breakpoint-up(md) {

    }

    //992px +
    @include media-breakpoint-up(lg) {
        .text-item {
            padding-top: 44px;
        }

        hr {
            margin-top: 44px;
        }
    }

    //1200px +
    @include media-breakpoint-up(xl) {

    }

}
