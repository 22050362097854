/**
*
* Global animation classes
*
**/

//VARS
$defaultAnimationTime: 250ms;
$defaultEase: cubic-bezier(0.19, 1, 0.22, 1); //Ease out Expo
$noEase: cubic-bezier(0.250, 0.250, 0.750, 0.750);;


@mixin animate-all($animationTime: $defaultAnimationTime, $delay: 0ms) {
    transition: all  $animationTime $defaultEase;
    transition-delay:  $delay;
}

/*
@include animate-custom(#{'border, color'}, 1500ms);
 */
@mixin animate-custom($properties, $animationTime: $defaultAnimationTime, $delay: 0ms, $easing: $defaultEase) {
    transition-property: $properties;
    transition-duration: $animationTime;
    transition-timing-function: $easing;
    transition-delay:  $delay;
}


//Parse $svg variable if the element your animatiing is an SVG - method of animating is different
@mixin animate-color($animationTime: $defaultAnimationTime, $svg: false) {
    @if ($svg == false){
        transition: color $animationTime ease;
    }
    @else {
        transition: fill $animationTime ease;
    }
}

%animate-none {
    transition: none;
}
