.module-feature-multi-height {
    position: relative;
    margin: 0;
    height: 500px;

    &.image-md {
        height: 350px;
        .image img {
            width: 100%;
            object-fit: cover;
        }
    }
    &.image-sm {
        height: 300px;
        .image img {
            width: 100%;
            object-fit: cover;
        }
    }
    &.image-xs {
        height: 200px;
        .image img {
            width: 100%;
            object-fit: cover;
        }
    }

    &.text-md {
        .title {
            @include fontSize(24px);
            @include lineHeight(32px);
        }
    }
    &.text-sm {
        .title {
            @include fontSize(20px);
            @include lineHeight(26px);
        }
    }
    &.text-xs {
        .title {
            @include fontSize(16px);
            @include lineHeight(22px);
        }
    }

    .image {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        picture {
            display: block;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: auto;
            height: 100%
        }
    }

    .caption {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        color: #FFFFFF;
    }
    
    &.darken .caption {
        background: rgba(0, 0, 0, 0.45);
    }

    .caption-wrapper {
        height: 100%;
    }

    .caption-content {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        text-align: left;
    }

    .title {
        @extend %h1;
        margin: 0;

        &.brand-name {
            @include fontSize(22px);
            @include lineHeight(22px);
            margin-bottom: 0;
        }
    }
    //992px +
    @include media-breakpoint-up(lg) {
        height: 600px;

        &.image-md {
            height: 400px;
        }
        &.image-sm {
            height: 340px;
        }
        &.image-xs {
            height: 290px;
        }

        &.text-md {
            .title {
                 @include fontSize(28px);
                 @include lineHeight(34px);
            }
        }
        &.text-sm {
            .title {
                @include fontSize(22px);
                @include lineHeight(28px);
            }
        }
        &.text-xs {
            .title {
                @include fontSize(20px);
                @include lineHeight(26px);
            }
        }
    }
}
