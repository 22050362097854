%h1 {
    @include fontSize(32px);
    @include lineHeight(40px);
    margin-bottom: 24px;
    font-weight: 900;

    @include media-breakpoint-up(lg) {
        @include fontSize(48px);
        @include lineHeight(64px);
        margin-bottom: 32px;
    }
}

%h2 {
    @include fontSize(20px);
    @include lineHeight(32px);
    margin-bottom: 24px;
    font-weight: 900;

    @include media-breakpoint-up(lg) {
        @include fontSize(40px);
        @include lineHeight(56px);
        margin-bottom: 32px;
    }
}

%h3 {
    @include fontSize(18px);
    @include lineHeight(28px);
    margin-bottom: 16px;
    font-weight: 900;

    @include media-breakpoint-up(lg) {
        @include fontSize(24px);
        @include lineHeight(40px);
        margin-bottom: 24px;
    }
}

%h4 {
    @include fontSize(16px);
    @include lineHeight(24px);
    margin-bottom: 12px;
    font-weight: 900;

    @include media-breakpoint-up(lg) {
        @include fontSize(20px);
        @include lineHeight(32px);
        margin-bottom: 24px;
    }
}

%h5 {
    @include fontSize(14px);
    @include lineHeight(22px);
    margin-bottom: 12px;
    font-weight: 900;

    @include media-breakpoint-up(lg) {
        @include fontSize(16px);
        @include lineHeight(24px);
        margin-bottom: 16px
    }
}

%h6 {
    @include fontSize(10px);
    @include lineHeight(16px);
    margin-bottom: 12px;
    font-weight: 900;

    @include media-breakpoint-up(lg) {
        @include fontSize(12px);
        @include lineHeight(16px);
        margin-bottom: 16px
    }
}

%body {
    @include fontSize(16px);
    @include lineHeight(24px);
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
        @include fontSize(20px);
        @include lineHeight(32px);
        margin-bottom: 24px
    }
}

h1 {
    @extend %h1;
}

h2 {
    @extend %h2;
}

h3 {
    @extend %h3;
}

h4 {
    @extend %h4;
}

h5 {
    @extend %h5;
}

h6 {
    @extend %h6;
}

p,
ul,
li {
    @extend %body;
}

li {
    margin-bottom: 0;
}

a {
    color: $primary_1;
    text-decoration: underline;

    &:hover {
        color: $primary_2;
    }
}

strong {
    font-weight: 900;
}