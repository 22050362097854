.module-experience-tiles {
    $halfColumn: (percentage(1 / 12) / 2);

    /*
    Core, default mobile first styles here
    */
    .tile {
        @include make-col-ready();
        @include make-col(10);
        @include make-col-offset(1);
        padding: 0;
        margin-bottom: verticalGrid(4);
    }

    .tile-img {
        position: relative;
        width: 100%;
    }

    .title-wrap {
        padding-top: 32px;
    }

    .title {
        @include fontSize(20px);
        @include lineHeight(32px);
        margin-bottom: 18px;
    }

    .tile-content {
        background: white;
        color: $neutral_1;

        p {
            @include fontSize(16px);
            @include lineHeight(24px);
            margin: 0;
        }
    }

    .tile-link {
        padding-top: 24px;
    }

    .icon-label {
        margin-top: 27px;
    }

    a.default{
        color: inherit;
        text-decoration: none;
        display: block;
   }


    //992px +
    @include media-breakpoint-up(lg) {
        .tile {
            @include make-col-ready();
            @include make-col(5);
            @include make-col-offset(1);
            padding-left: 0;
            padding-right: $halfColumn;
            margin-bottom: 0;

            &:nth-child(odd) {
                margin-bottom: verticalGrid(10);
            }

            &:nth-child(even) {
                @include make-col-offset(0);
                padding-left: $halfColumn;
                padding-right: 0;
                margin-top: verticalGrid(10);
            }
        }

        .title-wrap {
            padding: 26px 0 18px 0;
            background-color: white;
        }

        .title {
            @include fontSize(26px);
            @include lineHeight(32px);
            font-weight: 900;
            margin-bottom: 0;
        }

        .tile-content {

            p {
                @include fontSize(20px);
                @include lineHeight(32px);
                margin: 0;
            }
        }

        .icon-label {
            margin-top: 37px;
        }
    }
    //992px +
    @include media-breakpoint-up(xl) {
        .title-wrap {
            position: absolute;
            left: -1px; //To account for subpixel rendering, where 1px of image is visible
            bottom: -44px;
            width: 70%;
            padding: 42px 34px 0 1px;
        }

        .title {
            @include fontSize(40px);
            @include lineHeight(56px);
            font-weight: 900;
            margin-bottom: 0;
        }

        .tile-content {
            padding-top: 74px;
        }
    }
}
