$primary_1: #FF6732;
$primary_2: #0099CC;
$primary_3: #1f4782;

$secondary_1: #161032;
$secondary_2: #474068;
$secondary_3: #B4A5FA;

$neutral_1: #49403A;
$neutral_2: #818693;
$neutral_3: #BCAA9E;
$neutral_4: #F7F7F7;
$neutral_5: #FFFFFF;
$neutral_6: #969696;

$neutral_black: #000000;
$neutral_whote: #FFFFFF;

$error: #ed4e4e;