#stamp {
    position: relative;
    z-index: 201;

    .image-block {
        position: absolute;
        top: 200px;
        height: 100px;
        right: 0;
        border-radius: 60px 0 0 60px;
        width: 120px;
        background: #004685;
        overflow: hidden;
    }

    .badge {
        height: 100px
    }

    //768px +
    @include media-breakpoint-up(md) {
        .image-block {
            top: 400px;
        }
    }


    //1200px +
    @include media-breakpoint-up(xl) {
        .image-block {
            top: 630px;
        }
    }
}
