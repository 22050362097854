#master-footer {

    /*
    Core, default mobile first styles here
    */
    background-color: $secondary_1;
    color: $neutral_4;

    .site-info-column,
    .link-column,
    .location-hours-column {
        @include make-col(10);
        @include make-col-offset(1);
    }

    /*
    Site-info-column
     */
    .site-info-column {
        padding-top: 48px;
        padding-bottom: 30px;

        p {
            @include fontSize(14px);
            @include lineHeight(24px);
        }
    }

    .logo {
        margin-bottom: 30px;

        img {
            display: block;
            max-width: 170px;
            margin: auto;
        }
    }

    /*
    Link columns and sibling accordions
     */

    .link-column {
        border-bottom: solid 1px $secondary_2;
    }

    .btn-accordion-trigger {
        @include btnReset();
        @include fontSize(16px);
        @include lineHeight(24px);
        display: block;
        width: 100%;
        position: relative;
        padding: 19px 0 15px 0;
        font-weight: 900;
        text-align: left;
        color: $neutral_4;

        .icon {
            @include animate-custom(#{'transform'}, 500ms);
            width: 14px;
            height: 8px;
            position: absolute;
            right: 0;
            top: 40%;
            transform-origin: center center;
        }

        &:focus {
            outline: none;
        }

        &[aria-expanded="true"] {
            .icon {
                transform: rotate(-180deg);
            }
        }
    }

    .column-title {
        display: none;
    }

    .accordion-content {

        ul,
        li {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        ul {
            padding-bottom: 15px;

            a {
                @include fontSize(14px);
                @include lineHeight(32px);
                color: $secondary_3;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        p {
            @include fontSize(14px);
            @include lineHeight(24px);
        }
    }

    address {
        @include fontSize(14px);
        @include lineHeight(24px);
        color: $neutral_4;
        margin-bottom: 26px;
    }

    .getting-here {
        margin-bottom: 28px;
    }

    .opening-hours {
        p {
            @include fontSize(14px);
            @include lineHeight(24px);
            margin-bottom: 10px;
            color: $secondary_3;
        }

        .header {
            @include fontSize(16px);
            margin-bottom: 10px;
            color: $neutral_4;
            font-weight: 900;
        }
    }


    .secondary-links {
        padding-top: 32px;
        margin-bottom: 24px;

        ul {
            margin: 0;
            padding: 0;
        }

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 16px;
        }

        a {
            @include fontSize(14px);
            @include lineHeight(24px);
            color: $neutral_4;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .social-links {
        padding-bottom: 40px;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            display: inline-block;
            margin-right: 15px;
        }

        a {
            display: block;
            width: 30px;
            height: 30px;
            color: #fff;

            .icon {
                display: block;
                max-height: 100%;
                width: 100%;
            }
        }
    }

    /*
    Tertiary block -- legals, credit link etc
     */
    .tertiary-block {

        ul,
        li {
            margin: 0;
            padding: 0;
            color: $neutral_2
        }

        li {
            display: inline-block;
        }


        p,
        a {
            @include fontSize(10px);
            @include lineHeight(16px);
            color: $neutral_2;
            text-decoration: none;
        }

        a:hover {
            color: lighten($neutral_2, 20%);
        }

        p {
            margin-bottom: 2px;
        }

        .col-10 {
            border-top: solid 1px $secondary_2;
        }

        .col-lg-5 {
            border-top: none;
        }
        .copyright {
            padding-top: 15px;
        }
    }

    .terms-links {
        padding-bottom: 10px;
    }

    .credits {
        padding: 15px 0 40px 0;
    }

    .site-credit {
        a {
            color: white;

            &:hover {
                color: white;
                text-decoration: underline;
            }
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        /*
        Site-info-column
         */
        .logo {
            img {
                margin: 0;
            }
        }

        /*
        Nav lists + links
         */
        .btn-accordion-trigger {
            display: none;
        }

        .site-info-column,
        .link-column,
        .location-hours-column {
            @include make-col(2);
            @include make-col-offset(1);
        }

        .link-column ~ .link-column {
            @include make-col-offset(0);
        }

        .column-title {
            @include fontSize(20px);
            @include lineHeight(32px);
            display: block;
            margin-bottom: 30px;
            font-weight: 900;
        }

        .location-hours-column,
        .link-column {
            margin-top: 92px;
            border-bottom: 0;
        }

        .accordion-content {
            height: auto;
            border-bottom: none;
            display: block;
        }

        /*
        Secondary section grouping
         */
        .secondary-block > .row .col-10 {
            border-bottom: solid 1px $secondary_2;
        }

        .secondary-links {
            padding-top: 40px;
        }

        .social-links {
            padding-top: 40px;
            padding-bottom: 25px;
        }

        /*
        Tertiary block -- legals, credit link etc
         */
        .tertiary-block {
            .col-10 {
                border-top: solid 1px $secondary_2;
            }

            .copyright,
            .credits {
                padding-top: 23px;
            }

            .terms-links {
                padding-top: 17px;
            }
        }
    }
}

