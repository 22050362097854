/*
Custom overrides for 'pika' date picker, used by Umbraco forms
 */
$datePicker--active: $primary_1;

.is-selected {
    .pika-button {
        background: $datePicker--active !important;
        box-shadow: inset 0 1px 3px $datePicker--active !important;
    }
}

.is-today .pika-button {
    color: $datePicker--active !important;
}

.pika-button:hover,
.pika-row.pick-whole-week:hover .pika-button {
    background: $datePicker--active !important;
    color: white !important;
}

