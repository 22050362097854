.module-newsletter-signup-cta {
    /*
    Core, default mobile first styles here
    */
    background: $primary_1;
    width: 100%;
    padding: 27px percentage(1 / 5 );
    position: relative;
    top: - verticalGrid(10);
    margin-bottom: - verticalGrid(10);
    text-align: center;

    p {
        @include fontSize(18px);
        @include lineHeight(28px);
        color: $neutral_4;
        margin-bottom: 11px;
        position: relative;
        text-align: left;

        &:before {
            content: '';
            display: block;
            width: 24px;
            height: 2px;
            background-color: $neutral_4;
            position: absolute;
            left: - 35px;
            top: 13px;
        }
    }

    .btn-primary {
        display: inline-block;
    }

    //992px +
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        padding: 56px percentage(1 / 10 );

        .text-aligner {
            flex: 4 0 0;
            padding-right: 40px;
        }

        .btn-aligner {
            flex: 1 0 0;
            align-items: flex-end;
        }

        p {
            @include fontSize(32px);
            @include lineHeight(48px);
            margin-bottom: 0;
            padding-left: 73px;

            &:before {
                width: 48px;
                left: 0;
                top: 24px;
            }
        }

        a {
            float: right;
        }
    }
}
