.module-shopify-products {
    $light-blue: #4098c7;

    h1 {
        @include fontSize(36px);
        @include lineHeight(43px);
        color: $light-blue;
        margin-bottom: 42px;
    }
}
