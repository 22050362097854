.swiper-pagination.animated {

    /*
    Core, default mobile first styles here
    */
    .swiper-pagination-bullet {
        margin: 0 12.5px;
        border: solid 1px rgba(255,255,255,0.5);

        &.swiper-pagination-bullet-active {
            border: solid 1px rgba(255,255,255,1);
        }
    }


    //576px +
    @include media-breakpoint-up(sm) {

    }

    //768px +
    @include media-breakpoint-up(md) {

    }

    //992px +
    @include media-breakpoint-up(lg) {
        @include animatedPagination($direction: 'vertical');
    }

    //1200px +
    @include media-breakpoint-up(xl) {

    }

}
