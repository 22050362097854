.module-info-ctas {
    margin: 0;
    /*
    Core, default mobile first styles here
    */

    .btn-cta {
        @include animate-custom(#{'background-color'}, 1500ms);
        @include make-col-ready();
        @include make-col(6);
        @include fontSize(18px);
        @include lineHeight(28px);
        padding:  23px 17px 23px 20px;
        color: $neutral_4;
        font-weight: 900;
        text-decoration: none;

        &:first-child {
            background-color: $primary_1;
        }

        &:last-child {
            background-color: $secondary_1;
        }
    }

    p {
        margin: 0;
        padding: 0;
    }

    .primary {
        font-size: inherit;
        line-height: inherit;
    }

    .secondary {
        @include fontSize(16px);
        @include lineHeight(24px);
        font-weight: 300;
    }

    .btn-cta {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;

        .cta-content {
            padding-right: 5px;
        }

        .cta-arrow {
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        height: 0;
        margin-bottom: verticalGrid(8);

        .btn-cta {
            @include make-col(4);
            @include fontSize(24px);
            @include lineHeight(40px);
            padding: 30px 38px 30px 38px; // Top and Bottom Padding Updated to support text wrapping on longer content.
            position: relative;
            top: - verticalGrid(12);
            z-index: 200;

            .cta-arrow {
                @include animate-custom(#{'transform'}, 750ms);
                svg {
                    width: 32px;
                    height: 32px;
                }
            }

            &:hover {
                .cta-arrow {
                    transform: translateX(10px);
                }
            }

            &:first-child:hover {
                background-color: darken($primary_1, 15%);
            }

            &:last-child:hover {
                background-color: darken($secondary_1, 15%);
            }
        }

        .secondary {
            @include fontSize(20px);
            @include lineHeight(32px);
        }

    }

    //1200px +
    @include media-breakpoint-up(xl) {
        .btn-cta {
            padding: 40px 48px 40px 48px ;
        }
    }

}
