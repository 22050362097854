#master-header {

    /*
    Core, default mobile first styles here
    */
    position: fixed;
    width: 100%;
    height: 80px;
    z-index: 1000;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);

    .row,
    .col-10 {
        height: 100%;
    }

    .logo {
        @include animate-custom(#{'transform'}, 750ms);
        position: relative;
        max-width: 140px;

        padding-top: verticalGrid(2);
        z-index: 15;

        h1 {
            margin: 0;
            padding: 0;
        }

        a {
            display: block;
            position: relative;
            z-index: 15;
        }

        img {
            @include animate-custom(#{'opacity'}, 750ms);
        }
    }

    /*
    Logo images
     */
    .logo-coloured {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .nav-wrapper {
        @include animate-custom(#{'opacity'}, 500ms);
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: white;
        pointer-events: none; //Default 'closed' state
        opacity: 0; //Default 'closed' state
    }

    .btn-offcanvas {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 15;
    }

    /*
    State when nav is open
     */
    &.nav-open {
        .logo-default { opacity: 0; }
        .logo-coloured { opacity: 1; }

        .nav-wrapper {
            display: block;
            opacity: 1;
            pointer-events: all;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
    }

    //1200px +
    @include media-breakpoint-up(xl) {
        .logo {
            height: 100%;
            float: left;
            max-width: 180px;
            padding-top: 42px;
            a {
                height: 69px;
            }
            img {
                width: auto;
                height: 100%;
            }
        }
        @include animate-custom(#{'background, transform'}, 750ms);
        height: 152px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);

        .nav-wrapper {
            position: relative;
            height: auto;
            width: auto;
            padding-top: 67px;
            opacity: 1;
            text-align: right;
            pointer-events: all;
            background-color: transparent;
        }

        .btn-offcanvas {
            display: none;
        }

        &.nav-open {
            .nav-wrapper {
                display: flex; //Ensure correct properties if nav open
            }
        }

        /*
        Post scroll version
         */
        &.light {
            background: white;
            transform: translateY(-48px);

            .logo {
                transform: translateY(24px);
            }


            .logo-default {
                opacity: 0;
            }
            .logo-coloured {
                opacity: 1;
            }
        }
    }
}
