.module-social-links {
    margin: 0;
    position: relative;

    .links {
        position: absolute;
        right: 0;
        top: -34px;

        background: $neutral_4;

        ul {
            list-style: none;
            margin: 0;
            padding: 4px 12px;

            li {
                display: inline-block;
                &.label {
                    @include fontSize(9px);
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $neutral_2;
                }

                a {
                    color: $neutral_1;
                    padding: 0 3px;
                }

                svg {
                    width: 1em;
                    height: 1em;
                }
            }
        }

    }
    @include media-breakpoint-up(lg) {
        .links {
            top: 0;
        }
    }
}