.module-testimonial {
    // padding-top: verticalGrid(7);
    // padding-bottom: verticalGrid(7);
    font-family: $fontSecondary;
    color: $neutral_1;

    /*
    Core, default mobile first styles here
    */
    .quote {
        @include fontSize(24px);
        @include lineHeight(40px);
        @include textUnderline(rgba($neutral_3, 0.5));
        font-weight: bold;
    }

    .author {
        @include fontSize(16px);
        @include lineHeight(24px);
        padding-top: 18px;
    }


    //576px +
    @include media-breakpoint-up(sm) {

    }

    //768px +
    @include media-breakpoint-up(md) {

    }

    //992px +
    @include media-breakpoint-up(lg) {
        // padding-top: verticalGrid(10);
        // padding-bottom: verticalGrid(10);

        .quote {
            @include fontSize(40px);
            @include lineHeight(64px);
            @include textUnderline(rgba($neutral_3, 0.5), 16px);
        }

        .author {
            @include fontSize(20px);
            @include lineHeight(26px);
            padding-top: 24px;
            margin-bottom: 0;
        }
    }

    //1200px +
    @include media-breakpoint-up(xl) {

    }
}
