.count-down-popup {
    .modal-content {
        border: none;
    }

    .popup-content {
        background: white;
        position: absolute;
        top: 25%;
        left: 0;
        right: 0;
        max-width: 285px;
        transform: translateY(-50%);
        margin: auto;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        margin-right: 10px;
        font-size: 22px;
        font-weight: bold;
        color: white;
        cursor: pointer;
        float: right;
        background: transparent;
    }

    .title {
        font-size: 16px;
        color: #49403A;
        line-height: 19px;
        text-align: center;
        font-weight: bold;
        margin-top: 36px;
        padding: 0px 45px;
        text-align: center;
    }

    .desc {
        font-size: 10.5px;
        line-height: 13px;
        text-align: center;
        padding: 0 36px;
        margin-bottom: 0;
    }

    .months {
        padding: 0 9px;
    }

    .days {
        padding: 0 20px 0 9px;
    }

    .value {
        font-weight: bold;
    }

    .light-gray {
        color: $neutral_6;
    }

    .separator {
        color: $neutral_6;
        font-weight: bold;
    }

    .count-down {
        display: flex;
        font-size: 35px;
        line-height: 42px;
        text-align: center;
        margin-top: 9px;
        margin-bottom: 9px;
        justify-content: center;
        color: #150F32;
    }

    .note {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        margin-top: 18px;
    }

    .label {
        font-size: 8px;
        line-height: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .purchase-ticket {
        text-align: center;
        margin: 18px 0 40px 0;
    }

    @include media-breakpoint-up(lg) {

        .popup-content {
            max-width: 800px;
        }

        .inner {
            display: flex;
        }

        .close {
            float: right;
            font-size: 22px;
            margin-top: 17px;
            margin-right: 17px;
            color: black;
        }

        .title {
            font-size: 23px;
            line-height: 28px;
            padding: 0px 75px;
            clear: both;
            margin-top: 90px;
        }

        .desc {
            font-size: 15px;
            line-height: 18px;
            padding: 0 50px;
            margin-bottom: 20px;
        }

        .img-block {
            width: 100%;
        }

        .content-block {
            width: 100%;
        }

        .count-down {
            font-size: 40px;
            margin: 0;
        }

        .label {
            font-size: 8px;
            line-height: 10px;
        }

        .note {
            margin: 18px 0;
        }

        .purchase-ticket {
            margin: 0;
        }
    }
}
