.module-intro-image-text-block {
    margin: 0;

    background-color: white;
    color: #49403A;

    .text-inner {
        padding-top: 44px;
        margin-bottom: 44px;
    }

    h2 {
        @include fontSize(20px);
        @include lineHeight(32px);
        font-weight: 900;
        margin-bottom: 24px;
        padding: 0;
    }

    p {
        @include fontSize(18px);
        @include lineHeight(28px);
        margin: 0;
        padding: 0;
    }

    img {
        margin-bottom: 36px;
    }

    //992px +
    @include media-breakpoint-up(lg) {

        h2 {
            @include fontSizeInVW(992px, 28px);
            @include lineHeightInVW(992px, 40px);
            margin-bottom: 24px;

        }

        p {
            @include fontSizeInVW(992px, 16px);
            @include lineHeightInVW(992px, 24px);
        }

        img {
            margin-top: - verticalGrid(8);
            margin-bottom: - verticalGrid(10);
        }

        &.standalone {
            img {
                margin-bottom: 0;
            }
        }
    }
}
