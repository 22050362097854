@mixin animatedPagination($direction: 'horizontal', $bulletWidth: 9px, $bulletGutter: 120px){

    /*
    Core, default mobile first styles here
    */
    $animationEasing: cubic-bezier(0.250, 0.250, 0.750, 0.750);

    @if $direction == 'vertical' {

        /*
        Vertical Varient
         */
        width: $bulletWidth;
        height: auto;

        .swiper-pagination-bullet {
            display: block;
            width: $bulletWidth;
            height: $bulletWidth;
            margin: $bulletGutter / 2 0;

            .track {
                display: block;
                height: $bulletGutter / 2;
                width: 1px;
                background: rgba(255,255,255,0.5);
                position: relative;
                top: $bulletWidth - 1px;
                left: 3px;
            }

            .track-inner {
                @include animate-custom(#{'height'}, 5000ms, 0, $animationEasing);
                position: absolute;
                left: 0;
                top: 0;
                content: '';
                display: block;
                height: 0;
                width: 100%;
                background: rgba(255,255,255,1);
            }
        }

        .track-inner.is-animating {
            height: 100%;
        }

    } @else  {


        .swiper-pagination-bullet {
            width: $bulletWidth;
            height: $bulletWidth;
            margin: 0 $bulletGutter / 2;
            position: relative;

            .track {
                display: block;
                height: 1px;
                width: $bulletGutter;
                background: rgba(255,255,255,0.5);
                position: relative;
                left: $bulletWidth - 1px;
                top: 3px; //TODO - a more programmatic way to achieve this
            }

            .track-inner {
                @include animate-custom(#{'width'}, 5000ms, 0, $animationEasing);
                content: '';
                display: block;
                height: 100%;
                width: 0;
                background: white;
            }
        }


        .track-inner.is-animating {
            width: 100%;
        }
    }

    /*
    Common styles
     */
    .swiper-pagination-bullet {
        opacity: 1;
    }
    .swiper-pagination-bullet.has-animated {
        border: solid 1px rgba(255,255,255,1);
        opacity: 1;
        background: white;

        .track-inner {

        }
    }

    /*
    Always hide the animated line for the last slide
     */
    .swiper-pagination-bullet:last-child {
        .track {
            display: none;
        }
    }
}
