.module .module-article-listing {
    .pagination {
        display: block;
        @include make-col(10);
        @include make-col-offset(1);

        &:after {
            content: "";
            clear: both;
            display: table;
        }

        .btn-previous {
            float: left;
        }

        .btn-next {
            float: right;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {

    }
}