.module-image-text-block {
    $verticalOffset: 10;
    color: #49403A;

    /*
    Core, default mobile first styles here
    */
    .item {
        @include make-col(10);
        @include make-col-offset(1);
        background: white;
    }

    .content-inner {
        padding: 34px percentage(1 / 10) 40px percentage(1 / 10);

        p:last-of-type {
            margin-bottom: 0;
        }

        a.btn-secondary {
            margin-top: 30px;
        }
    }

    picture {
        display: block;
        height: 100%;
    }


    //992px +
    @include media-breakpoint-up(lg) {
        margin: 0; // Override the margin for the large version of the
        padding-bottom: 40px;

        .image,
        .content {
            width: 50%;
        }

        .content {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .content-inner {
            width: 100%;
            padding: 0 percentage(1 / 5);

            p:last-of-type {
                margin-bottom: 30px;
            }

            a.btn-secondary {
                margin-top: 0;
            }
        }

        /*
        Alignment varients
         */
        &.align-left {
            .item {
                .image,
                .content {
                    float: left;
                }

                &:nth-child(even) {
                    .image,
                    .content {
                        float: right;
                    }
                }
            }
        }

        &.align-right {
            .item {
                .image,
                .content {
                    float: right;
                }
                &:nth-child(even) {
                    .image,
                    .content {
                        float: left;
                    }
                }
            }
        }

        /*
        Vertical offsets and required corrections to content height (to vertically center text)
         */
        .item:nth-child(even) {
            margin-bottom: - verticalGrid($verticalOffset);

            .image {
                position: relative;
                z-index: 50;
            }

            .content {
                height: calc(100% - #{verticalGrid($verticalOffset)} );
            }
        }

        .item:nth-child(odd){
            .content {
                padding-top: verticalGrid($verticalOffset);
            }
        }
    }
}
