.module-feature {
    position: relative;
    margin: 0;
    height: 500px;

    &.reduce-height {
        height: 350px;

        .image img {
            width: 100%;
            object-fit: cover;
        }
    }

    .image {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        picture {
            display: block;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: auto;
            height: 100%
        }
    }

    .caption {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.45);
        color: #FFFFFF;
    }

    .caption-wrapper {
        height: 100%;
    }

    .caption-content {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        text-align: left;
    }

    .title {
        @extend %h1;
        margin: 0;

        &.brand-name {
            @include fontSize(22px);
            @include lineHeight(22px);
            margin-bottom: 0;
        }
    }
    //992px +
    @include media-breakpoint-up(lg) {
        height: 600px;

        &.reduce-height {
            height: 400px;

            .title {
                font-size: 2rem;
                line-height: 3rem;
            }

            .caption-content{
                top: 55%;
            }
        }
    }
}
