/*
Swiper controls
 */
.swiper-pagination-bullet {
    background: transparent;
    border: 1px solid #FFFFFF;
    opacity: 0.5;
    margin: 0 12px;
}

.swiper-pagination-bullet-active {
    background: #FFFFFF;
    opacity: 1;
}
.swiper-button-prev,
.swiper-button-next {
    @include animate-custom(#{'background, transform'}, 500ms);
    transform: translateY(-100%);
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background: $secondary_1;
    margin-top: 0;

    .icon {
        position: relative;
        left: 7px;
        top: 5px;
        width: 20px;
        height: 20px;
        color: white;
    }

    &:hover {
        background-color: $neutral_1;
    }

    &:focus {
        outline: none;
    }

    &:active {
        background-color: lighten($neutral_1, 15%);
    }

    //992px +
    @include media-breakpoint-up(lg) {
        width: 56px;
        height: 56px;
        border-radius: 28px;

        .icon {
            left: 13px;
            top: 14px;
            width: 31px;
            height: 31px;
        }
    }
}
.swiper-button-prev {
    left: 17px;

    &:active {
        transform: translateX(-3px) translateY(-100%);
    }

    //992px +
    @include media-breakpoint-up(lg) {
        left: 30px;
    }
}

.swiper-button-next {
    right: 17px;

    &:active {
        transform: translateX(3px) translateY(-100%);
    }

    //992px +
    @include media-breakpoint-up(lg) {
        right: 30px;
    }
}